<template>

    <div>

        <Breadcrumb titulo="SETOR" backgroundColor="#D27F3F" style="color: white;" />

        <div class="mainContainer">

            <div class="itemsContainer">

                <div id="searchBar" class="mr-auto d-flex">

                    <input type="text" class="pesquisaSetores" v-model="searchTerm" placeholder="Buscar Setor">
                    <i id="lupaSearch" class="fas fa-search" @click=""></i>

                    <b-button id="setorButtom" class="box-cliente rounded-0" @click="criarSetor">Novo Setor</b-button>

                </div>

                <!-- ROW DETAILS TABLE ----------------------------------------------------------------------->

                <div class="TabelaSetores">
                    <b-table :items="setoresFiltrados" :fields="fields" striped responsive="sm" class="B-Table" style="border-bottom: 1px #DEE2E6 solid;">

                        <template #head(Setor)="row" class="TituloRowTable">
                            <span class="TituloRowTable">Setores</span>


                        </template>
                        <template #cell(Setor)="row">
 
                            <i id="Setas" @click="expandirSetores(row.toggleDetails, row.item.Nome)" :class="`fas fa-angle-${row.detailsShowing ? 'down' : 'right'} cursor-pointer`"></i>
                            <i @click="row.toggleDetails" style="cursor:pointer" class="NomeSetor">{{row.item.nomeSetor}}</i>

                        </template>
                        <template #cell(botoes)="editCanais">
                            <div Id="botoesEdicao" class="w-100 text-right">
                                <i title="Editar" class="fas fa-pen mx-2" @click="editarSetor(editCanais.item)" style="cursor:pointer"></i>
                                <i title="Remover" class="fas fa-trash-alt mx-2" @click="removerSetor(editCanais.item.idSetor)" style="cursor:pointer"></i>

                            </div>
                        </template>

                        <template #row-details="row">
                            <!--Card de dentro do setor----------------------------------->

                            <b-card>
                                <b-table id="tableCanais" :items="row.item.itemSetor" :fields="colunasSetor" striped style="border: 1px #DEE2E6 solid;">

                                    <template #cell(Usuarios)="row">

                                        <i class="fas fa-users"></i>
                                        <span> {{row.item.Usuarios}} usuários</span>

                                    </template>

                                    <template #cell(Conectado)="row">

                                        <i v-if="row.item.Conectado" class="fas fa-circle text-success" style="font-size:12px;"></i>
                                        <i v-else class="fas fa-circle text-danger" style="font-size:12px;"></i>

                                        <span v-if="row.item.Conectado" style="color: forestgreen;"> Online</span>
                                        <span v-else="row.item.Conectado" style="color: red;"> Offline</span>

                                    </template>

                                </b-table>

                            </b-card><!----------------------------------------------------------------------->

                        </template>

                    </b-table>

                </div><!------------------------------------------------------------------------->

            </div> 

        </div>

        <setor-modal ref="setor-modal" @atualizarSetor="atualizarSetor"></setor-modal>

    </div>

</template>


<!--=====================================================================================================-->

<script>

    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import setorModal from '@/components/setorNewPage/setorModal.vue'
    import axios from "axios";

    export default {

        components: {
            Breadcrumb,
            setorModal
        },


        // DADOS  ====================================
        data() {

            return {
                fields: ['Setor', {key: 'botoes', label: ''}],
                setores: [],
                colunasSetor: ['Canal', 'Detalhe', { key: "Conectado", label: "Status da Conexão" }, { key: "Usuarios", label: "Usuários Conectados" }],

                textoPesquisa: '',
                dadosSetores: '',
                searchTerm: null
            }
        },


        // |=============| COMPUTED |=========================|
        computed: {

            setoresFiltrados() {
                let listaSetores = this.setores.sort((a, b) => a.nomeSetor.toLowerCase() > b.nomeSetor.toLowerCase() ? 0 : -1);
                if (!this.searchTerm?.trim()) return listaSetores;
                return listaSetores.filter(setores => {
                    if (setores.nomeSetor?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.searchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())) return setores;
                });
            }
        },


        // |=============| REQUISIÇÃO DE SETORES CREATED |=========================|

        created() {

            axios.get("api/setor").then(response => {
                this.setores = response.data.map(item => ({
                    nomeSetor: item.Nome, 
                    idSetor: item.Id,
                    itemSetor: [],

                })

                );

                //DADOS MOCKADOS: EXCLUIR -----------------------        
                {

                    this.setores[0].itemSetor = [
                        { Canal: `Whatsapp`, Detalhe: "+55014991456632", Conectado: true, Usuarios: 10 },
                        { Canal: `E-mail`, Detalhe: "shaolinsensei@gmail.com", Conectado: false, Usuarios: 3 }
                    ];

                    this.setores[1].itemSetor = [
                        { Canal: `E-mail`, Detalhe: "flavindopneu@gmail.com", Conectado: true, Usuarios: 8 },
                    ];

                    this.setores[2].itemSetor = [
                        { Canal: `E-mail`, Detalhe: "ademarmessias.com", Conectado: true, Usuarios: 1 },
                    ];

                    this.setores[3].itemSetor = [
                        { Canal: `Whatsapp`, Detalhe: "+551354665334", Conectado: false, Usuarios: 5 },
                    ];

                    this.setores[4].itemSetor = [
                        { Canal: `E-mail`, Detalhe: "jairdaburracha@gmail.com", Conectado: true, Usuarios: 69 },
                    ];
                }

            });

        },


        // |========================| METHODS |===================================|
        methods: {


            expandirSetores(toggleDetaisFn, Nome) {
                if (typeof toggleDetaisFn == "function") toggleDetaisFn();
                let nomeSetor = this.nomeSetor.find(a => a.Nome == Nome);
            },


            criarSetor() {
                this.$refs["setor-modal"].iniciar();

            },

            editarSetor(setor) {
                this.$refs["setor-modal"].iniciar(setor);

            },

            atualizarSetor(dadosSetor) {
                let setor = this.setores.find(item => item.idSetor == dadosSetor.Id);
                if (!setor) {

                    this.setores.push({
                        nomeSetor: dadosSetor.Nome,
                        idSetor: dadosSetor.Id,
                        itemSetor: []
                    });

                } else {

                    setor.nomeSetor = dadosSetor.Nome;

                }
            },
            
            removerSetor: function (setorId) {
                this.$bvModal.msgBoxConfirm("Deseja excluir o setor", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        axios.delete(`api/setor/${setorId}`)
                            .then(response => {

                                this.atualizarSetores();

                                console.log(response);
                            })
                            .catch(error => {
                                console.error(error);
                            });

                            
                    }
                })
            },


            atualizarSetores: function () {


                axios.get("api/setor").then(response => {
                    this.setores = response.data.map(item => ({
                        nomeSetor: item.Nome,
                        idSetor: item.Id,
                        itemSetor: [],
                    })
                    );
                })

            }

        },

    }

</script>


<!--=====================================================================================================-->


<style scoped>

    .mainContainer {

        padding: 10px;
        color: #757575;
    }

    .itemsContainer {

        padding: 10px;
        height: 100%;
        background-color: var(--cinza-3);
    }

    .pesquisaSetores {
        width: 400px !important;
        border: none;
        opacity: 0.7;
    }

    .pesquisaSetores:focus {

        opacity: 1;
        border-bottom: 0.5px #123461 solid;
    }

    #searchBar {
        margin: 0 auto;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    #lupaSearch {
        color: white;
        background-color: #123461;
        padding: 9px;
        padding-left: 15px;
        padding-right: 15px;
    }

    #setorButtom {
        width: 130px;
        font-size: 12px;
        padding: 8px;
        border: hidden;
        margin-left: auto;
        
    }

    #Setas {
        font-size: 20px;
        color: #717373;
        background-color: none;
        border: hidden;
    }


    .Botao_Seta {
        border: hidden;
        background-color: transparent;
        margin-right: 20px;
    }


    .TabelaSetores {
        margin: 10px auto;
        padding: 10px;
        padding-top: 0;
        background-color: #FDFDFD;
    }

    .table th, .table td {
        color: #787878;
        vertical-align: top;
    }


    .TituloRowTable {
        color: #787878;
        margin-left: 40px;
        font-size: 15px;
    }


    .NomeSetor {
        color: #9A9A9A;
        font-style: normal;
        margin-left: 30px;
    }


    .rowCard {
        max-height: 100px;
    }

    #tableCanais th {
        background-color: white;
    }

    #botoesEdicao {
        font-size: 15px;
    }

</style>