
<template>

    <b-modal id="setor-modal" ref="setorModal" size="lg" centered title="Novo Setor" class="titleModal" @hide="limparFormulario">

        <b-container>

            <b-col>

                <div class="data-field">

                    <label>Nome do setor</label>
                    <input type="text" v-model="nome" placeholder="Nome do Setor" />
                </div>

                <div class="data-field">

                    <label>Habilitar finalização automática do atendimento</label>
                    <select class="selectBox" v-model="finalizacaoAutomatica">

                        <option value="1" selected>Sim</option>
                        <option value="2">Não</option>

                    </select>
                </div>


                <div class="data-field">

                    <label>Selecione o período de ociosidade</label>
                    <select class="selectBox">

                        <option value="1">6h</option>
                        <option value="2">12h</option>
                        <option value="3">24h</option>
                        <option value="4">42h</option>
                        <option value="5">72h</option>

                    </select>
                </div>          
                
                <div class="data-field">

                    <label> Status de atendimento </label>
                    <div class="statusAtendimento">
                        <div v-for="option in options">
                            <input type="checkbox" :id="`status-${option.value}`" :value="option.value" v-model="selected">
                            <label :for="`status-${ option.value}`">{{ option.text}}</label>
                        </div>
                    </div>

                </div>

</b-col>

        </b-container>

        <template #modal-footer="{ cancel }">

            <b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>

            <b-button variant="success" @click="salvarSetor" class="rounded-0" :disabled="!isValid || salvandoSetor">
                <span v-if="salvandoSetor">
                    <b-spinner small class="mr-1"></b-spinner>
                    Salvando...
                </span>
                <span v-else>Salvar</span>
            </b-button>

        </template>

    </b-modal>

</template>

<script>

    import axios from "axios";

    export default {

        name: 'SetorModal',

        data() {

            return {

                id: null,
                nome: null,
                finalizacaoAutomatica: null,
                periodoOciosidade:null,
                salvandoSetor: false,


                options: [
                    { text: 'Ativo', value: 'ativo' },
                    { text: 'Pendente', value: 'pendente' },
                    { text: 'Suspenso', value: 'suspenso' },
                    { text: 'Agendado', value: 'agendado' }
                ],

                selected: [],

            }

        },

        computed: {

            isValid() {

                if (!this.nome?.trim()) {

                    return false

                } else {

                    return true

                }

            }

        },




        methods: {

            iniciar(setor = null) {
                this.id = setor?.idSetor;
                this.nome = setor?.nomeSetor;
                this.$bvModal.show("setor-modal");
            },

            postarSetor() {

                this.salvandoSetor = true;
                axios.post('api/setor', { nome: this.nome })
                    .then(response => {

                        console.log(response);

                        this.salvandoSetor = false;

                        this.$emit("atualizarSetor", response.data);

                        this.fecharModal();
                    })
                    .catch(error => {
                        console.error(error);
                        this.salvandoSetor = false;
                    });
            },


            editarSetor() {

                this.salvandoSetor = true;
                let nome = { Nome: this.nome, Id: this.id };

                axios.put(`/api/setor/${this.id}`, nome)
                    .then(response => {
                        console.log(response);

                        this.salvandoSetor = false;

                        this.$emit("atualizarSetor", {
                            Id: this.id,
                            Nome: this.nome
                        });

                        this.fecharModal();
                    })
                    .catch(error => {
                        console.error(error);
                        this.salvandoSetor = false;
                    });
            },

            salvarSetor() {
                if (this.id) {
                    this.editarSetor();
                } else {
                    this.postarSetor();
                }
            },



            limparFormulario: function () {

                this.id = null;
                this.nome = null;

            },



            fecharModal: function () {
                this.$refs['setorModal'].hide();

            },
        }
    }

</script>

<style scoped>

    .selectBox {
        opacity: 0.5;
        background-color: #F5F2F2;
    }

    .selectBox:focus {
        opacity: 0.5;
        background-color: #F5F2F2;
    }

    .data-field {
        color: #787878;
    }

    input[type=select] {
        opacity: 0.5;
        background-color: #F5F2F2;
    }

    input[type=text] {
        opacity: 0.5;
        background-color: #F5F2F2;
    }

    input[type=text]:focus {
        opacity: 1;
        background-color: #F5F2F2;
    }



    .statusAtendimento {
        background-color: #F2F2F2;
        border: 1px #CCCCCC solid;
    }

        .statusAtendimento input[type=checkbox] {
            display: none;
        }

        .statusAtendimento label {
            font-size: 12px;
            cursor: pointer;
            padding: 5px;
            width: 100%;
            opacity: 0.5;
            background-color: #F5F2F2;
            margin: 0;
        }

        .statusAtendimento input[type=checkbox]:hover + label {
            opacity: 1;
        }

        .statusAtendimento input[type=checkbox]:checked + label {
            background-color: #E8E8E8;
            opacity: 1;
        }

</style>